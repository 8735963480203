<template>
  <RevInputSelectSearchable
    id="swap-select"
    :clear-button-aria-label="i18n(translations.clearButtonAriaLabel)"
    :defaultOption
    :hideCheckboxes="true"
    :label
    listbox-placement="bottom"
    :model-value="selectModel"
    :no-options-message="i18n(translations.swapSelectNoOptionsMessage)"
    :options="filteredOptions"
    @focus="handleFocus"
    @text-change="handleTextChange"
    @update:model-value="
      (option) => handleModelValueUpdate(option as OptionItem)
    "
  />
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevInputSelectSearchable } from '@ds/components/InputSelectSearchable'

import { useBuybackPopularSearches } from '~/scopes/buyback/composables/useBuybackPopularSearches'
import {
  type BuybackProduct,
  useBuybackSearch,
} from '~/scopes/buyback/composables/useBuybackSearch'

import translations from './TradeInSearchBar.translations'

type OptionItem = {
  id: number | string
  label: string
  value: string
}
interface SearchProduct {
  label: string
  suffix: string
  id: string
  value: string
}

const props = defineProps<{ initialQuery?: string }>()

const emits = defineEmits<{
  (e: 'item-selected', value: BuybackProduct): void
  (e: 'default-item-selected'): void
}>()

const i18n = useI18n()
const { query, hits } = useBuybackSearch('standard-search', {
  debounce: true,
  debounceTime: 300,
})
const { popularSearches } = useBuybackPopularSearches()
const { trackClick, trackFormSubmit } = useTracking()

const selectModel = ref<OptionItem | null>(null)

function buybackProductsToSearchProduct(
  products: BuybackProduct[],
): SearchProduct[] {
  return products.map((product) => ({
    label: product.model,
    suffix: i18n(translations.swapSelectSuffixOption, {
      price: i18n.price(product.price, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }),
    id: product.id,
    value: product.id,
  }))
}

const defaultOption = {
  id: 'default',
  label: i18n(translations.swapSelectLabelOptionDefault),
  value: 'default',
}

const filteredOptions = computed(() => {
  if (query.value === '') {
    return buybackProductsToSearchProduct(popularSearches.value)
  }

  return buybackProductsToSearchProduct(hits.value)
})

const label = computed(
  () => props.initialQuery || i18n(translations.swapSelectPlaceholder),
)

function handleTextChange(value: string) {
  query.value = value
}

function handleModelValueUpdate(option: OptionItem) {
  selectModel.value = option
  if (option.value === defaultOption.value) {
    emits('default-item-selected')

    trackFormSubmit({
      zone: 'tradein_searchbar',
      name: option.value,
      payload: {
        search_type: 'no result',
        query: query.value,
      },
    })

    return
  }

  const selectedHit = [...hits.value, ...popularSearches.value].find(
    (hit) => hit.id === option.id,
  )
  if (selectedHit) {
    emits('item-selected', selectedHit)
    trackFormSubmit({
      zone: 'tradein_searchbar',
      name: selectedHit?.model ?? '',
      payload: {
        product_public_id: option.id,
        search_type: query.value === '' ? 'popularSearches' : 'suggestions',
      },
    })
  }
}

function handleFocus() {
  trackClick({
    zone: 'swap',
    name: 'yes',
  })
}

watch(
  [() => props.initialQuery],
  ([newInitialQuery]) => {
    if (newInitialQuery) {
      query.value = newInitialQuery
    }
  },
  { immediate: true },
)
</script>
